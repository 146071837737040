<template>
  <div>
    <transition v-bind="transitionOpacity">
      <div v-if="isEcomUser">
        <transition v-bind="transitionOpacity">
          <div v-if="isLoading">
            <aw-spinner />
          </div>
          <div v-else-if="foodDeliveryAvailable">
            <aw-best-timeframe-options
              v-if="activeStep === steps.TIMEFRAME_OPTIONS"
              v-model:is-calendar-expanded="isCalendarExpanded"
              v-model:is-best-timeframes-expanded="isBestTimeframesExpanded"
              @select-timeframe="selectTimeframe"
              @submit-form="event => $emit('submit-form', event)"
            />
            <aw-delivery-data-table v-if="activeStep === steps.DELIVERY_DATA_TABLE" @go-to-step="goToStep" />
          </div>
          <div v-else>
            <aw-notification-item
              :notification="{
                type: 'info',
                iconName: 'education-16',
                manualClose: false,
              }"
              thick-content
              index="1"
            >
              <template #subtitle>
                <p v-html="$awt('aw.common.delivery_method_modal.step3.food_delivery_available_false_notification.subtitle')" />
              </template>
            </aw-notification-item>
          </div>
        </transition>
      </div>
      <aw-notification-item
        v-else
        :notification="{
          type: 'info',
          iconName: 'education-16',
          manualClose: false,
        }"
        thick-content
        index="1"
      >
        <template #subtitle>
          <p v-html="$awt('aw.common.delivery_method_modal.step3.login_required.subtitle')" />
        </template>
      </aw-notification-item>
    </transition>
    <aw-notification
      aria-live="assertive"
      :notifications="localNotis"
    />
  </div>
</template>

<script>
  import { defineAsyncComponent, nextTick } from 'vue';
  import { mapState } from 'pinia';
  import AwBestTimeframeOptions from './timeframe/AwBestTimeframeOptions';
  import AwDeliveryDataTable from './timeframe/AwSelectedDeliveryDataTable';
  import modalSteps from './enum/modalStepsEnum';
  import steps from './enum/timeframeStepsEnum';
  import buttonLayouts from './enum/buttonLayoutsEnum';
  import {
    PUSH_CHANGE_DELIVERY_METHOD,
    CDM_RES,
  } from '~~/common/plugins/aw-analytics.js';
  import { transitionOpacity } from '~~/common/utils/transition.js';
  import { useCheckoutStore } from '~~/common/stores/checkout';
  import { useUserStore } from '~~/common/stores/user';
  import { useDeliveryStore } from '~~/shop/stores/delivery';

  export default {
    name: 'AwDeliveryTimeframeForm',
    components: {
      AwDeliveryDataTable,
      AwBestTimeframeOptions,
      AwSpinner: defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner')),
      AwNotificationItem: defineAsyncComponent(() => import('~~/common/components/Common/AwNotificationItem')),
      AwNotification: defineAsyncComponent(() => import('~~/common/components/Common/AwNotification')),
    },
    props: {
      onlyTimeframes: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'submit-form',
      'validation-status-changed',
      'change-button-layout',
      'is-success-state',
      'go-to-step',
      'close-modal',
    ],
    data () {
      return {
        transitionOpacity,
        errorNotificationText: '',
        isLoading: true,
        activeStep: null,
        steps,
        modalSteps,
        buttonLayouts,
        isCalendarExpanded: false,
        isBestTimeframesExpanded: true,
      };
    },
    computed: {
      ...mapState(useDeliveryStore, {
        timeframes: state => state.timeframes,
        currentSelectedTimeframe: state => state.selectedTimeframe,
        selectedTimeframe: state => state.timeframeListSelectedTimeframe,
        foodDeliveryAvailable: state => state.foodDeliveryAvailable,
      }),
      ...mapState(useUserStore, {
        isEcomUser: state => state.isEcomUser,
      }),
      localNotis () {
        let idx = 0;
        const result = {};
        if (this.errorNotificationText) {
          result[idx++] = {
            uuid: 'err',
            type: 'error',
            iconName: 'error-16',
            manualClose: false,
            thickContent: true,
            text: {
              subtitle: this.errorNotificationText,
            },
          };
        }
        if (this.activeStep === this.steps.NOTIFICATION) {
          result[idx++] = {
            uuid: 'ok',
            type: 'success',
            iconName: 'success-16',
            manualClose: false,
            thickContent: true,
            text: {
              subtitle: this.$awt('aw.common.delivery_method_modal.step3.step5.notification.subtitle'),
            },
          };
        }
        return result;
      },
    },
    watch: {
      selectedTimeframe (newVal) {
        this.$emit('validation-status-changed', newVal && this.currentSelectedTimeframe?.id !== this.selectedTimeframe?.id);
      },
      activeStep (newVal) {
        this.errorNotificationText = '';
        this.recalculateButtonStatus(newVal);
      },
      foodDeliveryAvailable () {
        this.recalculateButtonStatus(this.activeStep);
      },
    },
    async mounted () {
      const deliveryStore = useDeliveryStore();
      if (!this.isEcomUser) {
        this.$emit('change-button-layout', { newLayout: this.buttonLayouts.LOGIN_REQUIRED });
        this.isLoading = false;
        return;
      }

      this.$emit('validation-status-changed', !!this.selectedTimeframe);
      await deliveryStore.fetchTimeframes().catch((e) => {
        this.$logger.error(e);
      });
      this.isLoading = false;
      if (this.onlyTimeframes) {
        this.activeStep = this.steps.TIMEFRAME_OPTIONS;
        this.isBestTimeframesExpanded = false;
        this.isCalendarExpanded = true;
      } else if (this.currentSelectedTimeframe) {
        this.activeStep = this.steps.DELIVERY_DATA_TABLE;
      } else {
        this.activeStep = this.steps.TIMEFRAME_OPTIONS;
      }
    },
    methods: {
      recalculateButtonStatus (step) {
        if (step === this.steps.DELIVERY_DATA_TABLE) {
          this.$emit('change-button-layout', {
            newLayout: this.buttonLayouts.NEXT_AND_CANCEL,
            layoutBtnText: this.$awt('aw.common.delivery_method_modal.buttons.init_reservation'),
          });
          this.$emit('is-success-state', false);
          return;
        }
        if (step === this.steps.NOTIFICATION) {
          this.$emit('change-button-layout', {
            newLayout: this.buttonLayouts.OK,
            layoutBtnText: this.$awt('aw.common.delivery_method_modal.buttons.close_after_success'),
          });
          this.$emit('is-success-state', true);
          return;
        }
        if (step === this.steps.TIMEFRAME_OPTIONS && !this.foodDeliveryAvailable) {
          this.$emit('change-button-layout', { newLayout: this.buttonLayouts.OK_AND_CLOSE });
          this.$emit('validation-status-changed', true);
          return;
        }
        this.$emit('change-button-layout', { newLayout: this.buttonLayouts.NEXT });
        this.$emit('is-success-state', false);
      },
      goToStep (payload) {
        if (payload === this.modalSteps.DELIVERY_TIMEFRAME) {
          this.activeStep = this.steps.TIMEFRAME_OPTIONS;
          this.isBestTimeframesExpanded = false;
          this.isCalendarExpanded = true;
        } else {
          this.$emit('go-to-step', payload);
        }
      },
      async onSubmit () {
        if (this.activeStep === this.steps.TIMEFRAME_OPTIONS) {
          if (this.onlyTimeframes) {
            this.isLoading = true;
            await this.saveTimeframe(this.selectedTimeframe);
            this.isLoading = false;
            this.$emit('close-modal');
          } else {
            this.activeStep = this.steps.DELIVERY_DATA_TABLE;
            return;
          }
        }
        if (this.activeStep === this.steps.DELIVERY_DATA_TABLE) {
          this.isLoading = true;
          const isSuccess = await this.saveTimeframe(this.selectedTimeframe);
          this.isLoading = false;
          if (isSuccess) {
            this.activeStep = this.steps.NOTIFICATION;
          }
          return;
        }
        if (this.activeStep === this.steps.NOTIFICATION) {
          this.$emit('close-modal');
        }
      },
      async saveTimeframe (timeFrame) {
        this.errorNotificationText = '';
        const deliveryStore = useDeliveryStore();
        const checkoutStore = useCheckoutStore();
        const {
          type,
          id,
          from,
          to,
        } = timeFrame;
        const result = await checkoutStore.updateCheckoutData({
          isErrorPopupRequested: false,
          data: {
            reservation: {
              type,
              id,
              from,
              to,
            },
          },
          type: 'FOOD',
        });
        if (result.status) {
          nextTick(() => {
            deliveryStore.selectTimeframe(timeFrame);
            deliveryStore.setExpiredVisible(null);
            deliveryStore.setExpireSoonVisible(null);
            this.$cookies.set('time_frame_expired_soon_modal_closed', false, {
              path: '/',
              maxAge: 7 * 24 * 60 * 60,
            });
            try {
              this.$awAnalytics[PUSH_CHANGE_DELIVERY_METHOD]({
                deliveryMethodId: checkoutStore?.checkout?.food?.delivery?.deliveryMethod,
                checkoutType: 'FOOD',
                changeTypes: [CDM_RES],
              });
            } catch (error) {
              this.$logger.error(error);
            }
          });
        } else {
          this.errorNotificationText = result.error?.response?.data?.message;
        }
        return result.status;
      },
      selectTimeframe (timeframe) {
        const deliveryStore = useDeliveryStore();
        deliveryStore.setTimeframeListSelectedTimeframe(timeframe);
      },
    },
  };
</script>
