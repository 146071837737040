<template>
  <nav :aria-label="`${$awt('aw.global.you_are_here')}: ${activeStepIndex + 1}. ${steps[activeStepIndex] ? steps[activeStepIndex].name : ''}`">
    <ol :class="['ulReset', $style.steps, {[$style.hasDoneStep]: hasDoneStep}]">
      <li v-for="(step, i) in steps" :key="`step-${step.step}`" :class="$style.step">
        <div :class="$style.stepOption">
          <div
            :class="[
              $style.stepLogo,
              {
                [$style.stepLogoActive] : isActiveStep(step),
                [$style.stepLogoDone] : isDoneStep(step)
              },
            ]"
          >
            <div v-if="isDoneStep(step)" :class="$style.stepLogoCheck">
              <lv-icon aria-hidden="true" :size="16" name="check-version2-16" />
            </div>
            {{ step.index }}
          </div>
          <div :class="$style.stepLabelWrapper">
            <div
              :class="[
                $style.stepTitle,
                {
                  [$style.stepTitleActive]: isDoneStep(step) || isActiveStep(step),
                },
              ]"
            >
              {{ step.name }}
            </div>
            <div :class="$style.stepEditButton">
              <lv-button
                :aria-label="`${step.index}. ${$awt('aw.global.edit')} ${step.name}`"
                styling="link-primary"
                :class="[
                  $style.stepEditButtonText,
                  {
                    awSrOnly: !isDoneStep(step),
                  },
                ]"
                :disabled="!isDoneStep(step)"
                @click="editStep(step)"
              >
                {{ $awt('aw.global.edit') }}
              </lv-button>
            </div>
          </div>
        </div>
        <div
          v-if="i + 1 < steps.length"
          :class="[
            $style.line,
            {
              [$style.lineToActive]: getNextStep(step) === activeStep,
              [$style.lineFromActive]: isActiveStep(step),
            },
          ]"
        />
      </li>
    </ol>
  </nav>
</template>

<script setup>
  import { LvIcon, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';

  const props = defineProps({
    steps: {
      type: Array,
      required: true,
    },
    activeStep: {
      type: String,
      required: true,
    },
  });
  const $emit = defineEmits(['edit']);

  const activeStepIndex = computed(() => {
    return getIndexOfStep(props.activeStep);
  });
  const hasDoneStep = computed(() => {
    return activeStepIndex.value > 0;
  });
  function isActiveStep ({ step }) {
    return step === props.activeStep;
  }
  function isDoneStep ({ index }) {
    return (index - 1) < activeStepIndex.value;
  }
  function editStep ({ step }) {
    return $emit('edit', step);
  }
  function getNextStep ({ step: currentStep }) {
    return props.steps[getIndexOfStep(currentStep) + 1].step;
  }
  function getIndexOfStep (searchedStep) {
    return props.steps.findIndex(({ step }) => step === searchedStep);
  }
</script>

<style module lang="scss" rel="stylesheet/scss">

.steps {
  display: flex;
  align-items: flex-start;
  height: 84px;

  &.hasDoneStep {
    height: 100px;
  }
}

.step {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    flex: 1 1 100%;
  }

  &Option {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &Logo {
    @include font($font-weight-bold-v2, 16px, null);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: $color-text-tertiary;
    border: 1px solid $color-border-disabled;
    border-radius: 1000px;
    background-color: transparent;

    @include tablet(min) {
      @include font(null, 18px, null);
      width: 48px;
      height: 48px;
    }

    &Active {
      color: $color-text-sage;
      border: 1px solid $color-sage-450;
      background: $color-sage-450--12;
    }

    &Done {
      border: none;
      background: $color-button-disabled-light-bg;
    }

    &Check {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      color: white;
      border-radius: 1000px;
      background: $color-sage-450;

      @include tablet(min) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &LabelWrapper {
    position: absolute;
    top: calc(48px + 8px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 130px;

    @include tablet(min) {
      top: calc(48px + 16px);
    }
  }

  &Title {
    font-size: 14px;
    font-weight: $font-weight-bold-v2;
    line-height: 20px;
    color: $color-text-tertiary;

    &Active {
      color: $color-text-primary;
    }
  }

  &EditButton {
    height: 26px;

    &Text {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.line {
  $toActive: linear-gradient(90deg, $color-button-disabled-light-bg 50%, $color-sage-450 100%);
  $fromActive: linear-gradient(90deg, $color-sage-450 0%, $color-button-disabled-light-bg 50%);

  width: 100%;
  height: 2px;
  background: $color-button-disabled-light-bg;

  &FromActive {
    background: $fromActive;
  }

  &ToActive {
    background: $toActive;
  }
}
</style>
