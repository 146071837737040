<template>
  <div>
    <h3
      ref="componentTopHeading"
      tabindex="-1"
      class="awSrOnly awSrOnlyInitialW"
      v-text="$awt('aw.common.delivery_method_modal.step3.step2.title_date')"
    />
    <div :class="$style.day">
      <lv-button
        v-if="screenRange['desktop-medium-min']"
        type="button"
        styling="small-primary-dark-just-icon"
        shrink
        :class="[$style.dayArrowButton, $style.dayArrowButtonPrevious]"
        :aria-disabled="!scrollableLeft"
        :disabled-style="!scrollableLeft"
        :title="previousDayAriaLabel"
        :aria-label="previousDayAriaLabel"
        @click="scrollLeft"
      >
        <lv-icon
          name="arrow-left-24"
          :size="16"
          :inherit-color="true"
          style="vertical-align: unset;"
        />
      </lv-button>
      <div ref="dayChooserWrapper" :class="$style.dayChooserWrapper">
        <ul ref="dayChooser" :class="['ulReset', $style.dayChooser]" @scroll="onScroll">
          <li v-for="dayString in timeframeDays" :key="dayString" :class="$style.daySection">
            <div aria-hidden="true" :class="$style.daySectionTitle">
              {{ $date(dayString, { weekday: 'long' }) }}
            </div>
            <aw-radio-chooser-version2
              :model-value="selectedDay"
              :value="dayString"
              :disabled="!isActiveDay(dayString)"
              :name-prop="componentId"
              label-size="md"
              input-width="none"
              :class="$style.option"
              breakpoint="desktop-medium"
              @update:model-value="selectDay(dayString)"
            >
              <template #label>
                <span :class="$style.optionLabel">
                  <span class="awSrOnlyAbs" v-text="getAriaLabel(dayString)" />
                  <span aria-hidden="true" :class="$style.optionLabelMonth">
                    {{ $date(dayString, { month: 'short' }) }}
                  </span>
                  <span aria-hidden="true" :class="[$style.optionLabelDay, {[$style.optionLabelDayDisabled]: !isActiveDay(dayString)}]">
                    {{ $date(dayString, { day: '2-digit' }) }}
                  </span>
                </span>
              </template>
            </aw-radio-chooser-version2>
          </li>
        </ul>
      </div>
      <lv-button
        v-if="screenRange['desktop-medium-min']"
        type="button"
        styling="small-primary-dark-just-icon"
        shrink
        :class="[$style.dayArrowButton, $style.dayArrowButtonNext]"
        :aria-disabled="!scrollableRight"
        :disabled-style="!scrollableRight"
        :title="nextDayAriaLabel"
        :aria-label="nextDayAriaLabel"
        @click="scrollRight"
      >
        <lv-icon
          name="arrow-right-24"
          :size="16"
          :inherit-color="true"
          style="vertical-align: unset;"
        />
      </lv-button>
    </div>
    <h3
      class="awSrOnly awSrOnlyInitialW"
      v-text="`${$awt('aw.common.delivery_method_modal.step3.step2.title_time')} ${selectedDay ? '' : $awt('aw.common.delivery_method_modal.step3.step2.no_date')}`"
    />
    <template v-if="selectedDay">
      <aw-timeframe-list
        :timeframes-by-selected-day="timeframesBySelectedDay"
        @select-timeframe="event => $emit('select-timeframe', event)"
        @submit-form="event => $emit('submit-form', event)"
      />
      <aw-notification-item
        :notification="{
          type: 'info',
          iconName: 'education-16',
          manualClose: false,
          text: {
            subtitle: $awt( isShippingSelected ? 'aw.common.delivery_method_modal.step3.step2.notification.subtitle' : 'aw.timeframe.notification.preparation_fee')
          }
        }"
        index="0"
        :class="$style.notification"
      />
    </template>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { useId } from 'nuxt/app';
  import { mapState } from 'pinia';
  import { DELIVERY_AREA_MODEL } from '~~/shop/awPlugins/app-config';
  import { getTypeByTimeFrame, timeFrameTypes } from './timeFrameLengthHelper.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import { useCheckoutStore } from '~~/common/stores/checkout.js';
  import { LvIcon, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwTimeframeList from './AwTimeframeList.vue';
  import AwRadioChooserVersion2 from '~~/common/components/Common/Widgets/AwRadioChooserVersion2.vue';

  // NOTE: padStart is needed for safari when output is passed to new Date()
  // T00:00:00 is needed so local timezone is used (see: removeTZInfoFromDateStr)
  const modelFormatter = date => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T00:00:00`;

  export default {
    name: 'AwTimeframeCalendar',
    components: {
      AwTimeframeList,
      LvIcon,
      LvButton,
      AwRadioChooserVersion2,
      AwNotificationItem: defineAsyncComponent(() => import('~~/common/components/Common/AwNotificationItem')),
    },
    emits: [
      'select-timeframe',
      'submit-form',
    ],
    setup () {
      return {
        componentId: useId(),
      };
    },
    data () {
      const from = new Date();
      const isSunday = from.getDay() === 0;
      from.setDate(from.getDate() - (isSunday ? 6 : (from.getDay() - 1)));

      const to = new Date(from);
      to.setDate(to.getDate() + 13);

      return {
        from,
        to,
        DELIVERY_AREA_MODEL,
        selectedDay: null,
        scrollableLeft: false,
        scrollableRight: true,
        menuScrollTimer: null,
        selectedTab: null,
        startIndex: 0,
        endIndex: 7,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useCheckoutStore, {
        alreadySelectedTimeframe (state) {
          return state.checkout.food?.reservation;
        },
      }),
      timeframeDays () {
        const days = Array.from(this.$dateRangeOfDays([this.from, this.to]));
        return ((this.screenRange['desktop-medium-min']
          ? days.slice(this.startIndex, this.endIndex)
          : days
        )
          .map(modelFormatter)
        );
      },
      ...mapState(useDeliveryStore, {
        isShippingSelected (state) {
          return state?.setup?.type === this.DELIVERY_AREA_MODEL.PUBLIC_AREA;
        },
        timeframes: state => state.timeframes,
      }),
      previousDayAriaLabel () {
        const { $awt, $date } = this;
        const day = new Date(this.timeframeDays[0]);
        day.setDate(day.getDate() - 1);
        return `${$awt('aw.common.delivery_method_modal.step3.step2.scroll.left')} ${$date(day, { month: 'short' })} ${$date(day, { day: '2-digit' })} ${$date(day, { weekday: 'long' })}`;
      },
      nextDayAriaLabel () {
        const { $awt, $date } = this;
        const day = new Date(this.timeframeDays[this.timeframeDays.length - 1]);
        day.setDate(day.getDate() + 1);
        return `${$awt('aw.common.delivery_method_modal.step3.step2.scroll.right')} ${$date(day, { month: 'short' })} ${$date(day, { day: '2-digit' })} ${$date(day, { weekday: 'long' })}`;
      },
      timeframesBySelectedDayTabs () {
        return this.timeframesBySelectedDay?.map(current => current.title) || null;
      },
      timeframesBySelectedDay () {
        if (!this.selectedDay) {
          return null;
        }
        const timeframes = this.timeframes.filter(current => this.assertDays(new Date(current.from), new Date(this.selectedDay))).filter(timeFrame => timeFrame.status);
        const shortTimeframes = timeframes.filter(current => getTypeByTimeFrame(current) === timeFrameTypes.SHORT);
        const extendedTimeframes = timeframes.filter(current => getTypeByTimeFrame(current) === timeFrameTypes.EXTENDED);
        const fullDayTimeframes = timeframes.filter(current => getTypeByTimeFrame(current) === timeFrameTypes.FULL_DAY);

        const initiallySelectedTimeframe = (shortTimeframes[0] || extendedTimeframes[0] || fullDayTimeframes[0]);
        if (initiallySelectedTimeframe) {
          initiallySelectedTimeframe.hasAutofocus = true;
        }
        const result = [];
        if (shortTimeframes.length) {
          result.push({
            title: this.$awt('aw.common.delivery_method_modal.step3.step2.short_timeframe'),
            timeframes: shortTimeframes,
          });
        }
        if (extendedTimeframes.length) {
          result.push({
            title: this.$awt('aw.common.delivery_method_modal.step3.step2.normal_timeframe'),
            timeframes: extendedTimeframes,
          });
        }
        if (fullDayTimeframes.length) {
          result.push({
            title: this.$awt('aw.common.delivery_method_modal.step3.step2.full_day_timeframe'),
            timeframes: fullDayTimeframes,
          });
        }
        return result;
      },
    },
    watch: {
      timeframesBySelectedDayTabs (newVal) {
        if (newVal?.length) {
          this.selectTab(newVal[0]);
        }
      },
      selectedDay (newVal) {
        if (newVal) {
          this.removeSeparatorExtraClass();
        }
      },
    },
    mounted () {
      const deliveryStore = useDeliveryStore();
      deliveryStore.setSecondSeparatorWithoutMarginTop(true);
      this.$refs?.componentTopHeading?.focus();
      if (this.alreadySelectedTimeframe) {
        this.selectedDay = modelFormatter(new Date(this.alreadySelectedTimeframe.from));
        deliveryStore.setTimeframeListSelectedTimeframe(this.alreadySelectedTimeframe);
      }
    },
    beforeUnmount () {
      this.removeSeparatorExtraClass();
    },
    methods: {
      removeSeparatorExtraClass () {
        const deliveryStore = useDeliveryStore();
        deliveryStore.setSecondSeparatorWithoutMarginTop(false);
      },
      selectTab (title) {
        this.selectedTab = title;
      },
      onScroll () {
        this.scrollableLeft = this.$refs?.dayChooser?.scrollLeft ? this.$refs?.dayChooser?.scrollLeft !== 0 : false;
        this.scrollableRight = !this.scrolledRight();
      },
      scrolledRight () {
        return this.$refs.dayChooserWrapper.clientWidth + this.$refs.dayChooser.scrollLeft - this.$refs.dayChooser.scrollWidth === 0;
      },
      sideScroll (direction) {
        if (direction === 'left') {
          this.startIndex -= 1;
          this.endIndex -= 1;
          if (this.startIndex === 0) {
            this.scrollableLeft = false;
          }
          this.scrollableRight = true;
        } else if (direction === 'right') {
          this.startIndex += 1;
          this.endIndex += 1;
          this.scrollableLeft = true;
          if (this.endIndex === 14) {
            this.scrollableRight = false;
          }
        } else {
          this.$logger.error('Invalid scroll direction');
        }
      },
      scrollLeft () {
        if (this.scrollableLeft) {
          this.sideScroll('left');
        }
      },
      scrollRight () {
        if (this.scrollableRight) {
          this.sideScroll('right');
        }
      },
      assertDays (first, second) {
        if (!(first instanceof Date) || !(second instanceof Date)) {
          this.$logger.warn('Not instance of Date');
        }
        // TODO: keresni valmai elegánsabb megoldást
        return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
      },
      getAriaLabel (day) {
        const selectedPart = this.isSelectedDay(day) ? `(${this.$awt('aw.global.selected')})` : '';
        const actionText = this.$awt('aw.common.delivery_method_modal.step3.step2.day_render.aria_label');
        return `${this.$date(day, { month: 'short' })} ${this.$date(day, { day: '2-digit' })} ${selectedPart} ${this.$date(day, { weekday: 'long' })} ${actionText}`;
      },
      isActiveDay (day) {
        const result = this.timeframes.filter((currentDay) => {
          const currentDayDate = new Date(currentDay.date);
          return this.assertDays(new Date(day), currentDayDate);
        }).filter(timeFrame => timeFrame.status);
        return !!result.length;
      },
      isSelectedDay (day) {
        if (!this.selectedDay) {
          return false;
        } else {
          return this.assertDays(new Date(day), new Date(this.selectedDay));
        }
      },
      selectDay (day) {
        if (!this.isActiveDay(day)) {
          return;
        }
        this.selectedDay = day;
        this.$emit('select-timeframe', null);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.tabWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px;
  border-bottom: 1px solid $color-border;

  .tab {
    font-size: 14px;
    font-weight: $font-weight-bold-v2;
    line-height: 20px;
    padding: 12px;
    cursor: pointer;
    color: $color-text-secondary;

    &Active {
      color: $color-text-primary;
      border-bottom: 1px solid $color-text-primary;
    }
  }
}

.day {
  display: grid;
  grid-template-columns: auto;
  gap: 8px;
  width: 100%;

  @include desktop-medium(min) {
    grid-template-columns: 28px auto 28px;
    gap: 0;
  }

  &ArrowButton {
    margin-top: 70px;

    &Previous {
      margin-right: -4px;
    }

    &Next {
      margin-left: -4px;
    }
  }

  &Chooser {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: row;
    padding-right: 12px;
    padding-bottom: 20px;
    padding-left: 12px;
    gap: 8px;
    scrollbar-width: none;

    @include tablet(min) {
      padding-bottom: 40px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @include desktop-medium(min) {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    &Wrapper {
      overflow: hidden;
    }
  }

  &Section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    &Title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 20px;
      text-transform: capitalize;
      color: $color-text-secondary;
    }
  }
}

.option {
  width: 124px !important;
  padding: 11px 11px 11px 15px !important;

  @include desktop-medium(min) {
    width: 100% !important;
  }

  @include desktop-medium(min) {
    padding: 15px 15px 19px !important;
  }
}

.optionLabel {
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: nowrap;

  @include desktop-medium(min) {
    gap: 16px;
  }

  &Month {
    @include font(900, 12px, 16px);
    @include desktop-medium (min) {
      @include font(null, 14px, 20px);
    }
  }

  &Day {
    @include font(900, 16px, 20px);
    @include desktop-medium (min) {
      @include font(null, 18px, 24px);
    }

    &Disabled {
      color: $color-text-tertiary;
    }
  }
}

.timeframeListWrapper {
  display: grid;
  gap: 40px;
  margin-top: 40px;

  &Tab1 {
    grid-template-columns: auto;
  }

  &Tab2 {
    grid-template-columns: 1fr 1fr;
  }

  &Tab3 {
    grid-template-columns: auto 1fr 1fr;
  }

}

.notification {
  margin-top: 20px;
  margin-bottom: 0;

  @include tablet(min) {
    margin-top: 40px;
    margin-bottom: 0;
  }
}
</style>
