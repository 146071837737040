<template>
  <div
    ref="component"
    :class="$style.wrapper"
    @click="$emit('select-timeframe', timeframe)"
    @keydown.enter="() => { $emit('select-timeframe', timeframe); $emit('enter') }"
    @keydown.space="$emit('select-timeframe', timeframe)"
  >
    <div :class="$style.check">
      <lv-icon
        :size="24"
        fill
        :name="selected ? 'radio-checked-version2-24' : 'radio-unchecked-version2-24'"
        :class="$style.icon"
      />
    </div>
    <div :class="$style.date">
      {{ timeframe.displayFrom }} - {{ timeframe.displayTo }}

      <div v-if="timeframe.isExpress" :class="$style.express">
        {{ $awt('aw.common.delivery_method_modal.step3.step2.express') }}
      </div>
    </div>
    <div :class="$style.price">
      ({{ $price({ gross: timeframe.price.grossDiscounted }, timeframe.price.currency) }})
    </div>
  </div>
</template>

<script>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  export default {
    name: 'AwTimeframeDayRender',
    components: {
      LvIcon,
    },
    props: {
      timeframe: {
        type: Object,
        required: true,
      },
      selected: {
        type: Boolean,
        default: false,
      },
      hasAutofocus: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['select-timeframe', 'enter'],
    mounted () {
      if (this.hasAutofocus) {
        this.$refs.component.focus();
      }
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  position: relative;
  display: grid;
  align-items: start;
  width: 100%;
  height: 100%;
  cursor: pointer;
  gap: 0 8px;

  grid-template-areas: "check date"
                        "check price";
  grid-template-columns: 24px auto;

  .date {
    font-size: 12px;
    font-weight: $font-weight-regular-v2;
    line-height: 16px;
    margin-top: 4px;
    grid-area: date;

    @include tablet(min) {
      font-size: 14px;
      font-weight: $font-weight-normal-v2;
      line-height: 20px;
      margin-top: 2px;
    }

  }

  .price {
    font-size: 12px;
    font-weight: $font-weight-regular-v2;
    line-height: 16px;
    color: $color-text-secondary;
    grid-area: price;
    @include tablet(min) {
      font-size: 14px;
      font-weight: $font-weight-normal-v2;
      line-height: 20px;
    }

  }

  .check {
    grid-area: check;
  }

  .express {
    font-size: 12px;
    font-weight: $font-weight-regular-v2;
    line-height: 16px;
    display: inline-block;
    color: $color-special-warning;

    @include tablet(min) {
      font-size: 14px;
      font-weight: $font-weight-bold-v2;
      line-height: 20px;
    }

  }
}

.icon {
  width: 24px;
  height: 24px;
}
</style>
