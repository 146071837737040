<template>
  <div :class="$style.radioWrapper">
    <h3 class="awSrOnlyAbs" v-text="$awt('aw.common.delivery_method_modal.step3.step1.title')" />
    <aw-radio-group-version2
      v-if="isBestTimeframesExpanded"
      v-model="selectedTimeframeOption"
      :row="screenRange['tablet-min']"
      row-space="md"
      :legend-text="$awt('aw.common.delivery_method_modal.step3.step1.legend')"
      :class="$style.radioOptionsGroup"
    >
      <aw-radio-chooser-version2
        v-for="(timeframeOption, key) in bestTimeFrameOptions"
        :key="key"
        ref="firstRadio"
        :value="key"
        label-size="md"
        :input-width="Object.keys(bestTimeFrameOptions).length === 3 ? 'md' : 'lg'"
        @enter="event => $emit('submit-form', event)"
        @radio-mouseup="onMouseUp"
      >
        <template #label>
          <div :class="$style.timeframeOption">
            <span>{{ timeframeOption.title }}</span>
            <div v-if="timeframeOption.subtitle" :class="[$style.timeframeOptionDescription]" v-text="timeframeOption.subtitle" />
            <template v-if="Object.keys(bestTimeFrameOptions).length === 3">
              <div v-if="timeframeOption.date" :class="$style.timeframeOptionDescription" v-text="timeframeOption.date" />
              <div v-if="timeframeOption.time" :class="$style.timeframeOptionDescription" v-text="timeframeOption.time" />
            </template>
            <template v-else>
              <div v-if="timeframeOption.date" :class="$style.timeframeOptionDescription">
                {{ timeframeOption.date }} {{ timeframeOption.time }}
              </div>
            </template>

            <div v-if="timeframeOption.price" :class="$style.timeframeOptionDescription" v-text="timeframeOption.price" />
          </div>
        </template>
      </aw-radio-chooser-version2>
    </aw-radio-group-version2>
    <transition v-bind="transitionOpacity">
      <aw-timeframe-calendar v-if="isCalendarExpanded" @select-timeframe="selectTimeframe" @submit-form="$emit('submit-form')" />
    </transition>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import AwRadioGroupVersion2 from '~~/common/components/Common/Widgets/AwRadioGroupVersion2.vue';
  import AwRadioChooserVersion2 from '~~/common/components/Common/Widgets/AwRadioChooserVersion2.vue';
  import AwTimeframeCalendar from '~~/shop/components/Common/Modals/AwDeliveryMethodAndTimeframeModal/timeframe/AwTimeframeCalendar';
  import { transitionOpacity } from '~~/common/utils/transition.js';
  import { DELIVERY_AREA_MODEL } from '~~/shop/awPlugins/app-config';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useDeliveryStore } from '~~/shop/stores/delivery';

  export default {
    name: 'AwBestTimeframeOptions',
    components: {
      AwRadioGroupVersion2,
      AwRadioChooserVersion2,
      AwTimeframeCalendar,
    },
    props: {
      isCalendarExpanded: {
        type: Boolean,
        required: true,
      },
      isBestTimeframesExpanded: {
        type: Boolean,
        required: true,
      },
    },
    emits: [
      'submit-form',
      'update:isCalendarExpanded',
      'update:isBestTimeframesExpanded',
      'select-timeframe',
    ],
    data () {
      return {
        transitionOpacity,
        selectedTimeframeOption: '',
        DELIVERY_AREA_MODEL,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useDeliveryStore, {
        timeframes: state => state.timeframes,
        isShippingSelected (state) {
          return state?.setup?.type === this.DELIVERY_AREA_MODEL.PUBLIC_AREA;
        },
        bestTimeFrameOptions (state) {
          const options = state.bestTimeFrameOptions;
          const defaultOption = {
            title: this.$awt('aw.common.delivery_method_modal.step3.step1.chose_timeframe.title'),
            subtitle: this.$awt('aw.common.delivery_method_modal.step3.step1.chose_timeframe.subtitle'),
            date: null,
            time: null,
            price: null,
          };
          const result = {};
          const genereateOption = (title, timeframe) => {
            return {
              id: timeframe.id,
              title,
              subtitle: null,
              date: this.$date(timeframe.from, {
                month: 'long',
                day: '2-digit',
              }),
              time: timeframe.displayFrom + ' - ' + timeframe.displayTo,
              price: this.$price({ gross: timeframe.price.grossDiscounted }, timeframe.price.currency),
            };
          };
          if (options.earliestTimeframe) {
            result.earliestTimeframe = genereateOption(this.$awt('aw.common.delivery_method_modal.step3.step1.earliest'), options.earliestTimeframe);
          }
          if (options.cheapestTimeframe && this.isShippingSelected) {
            result.cheapestTimeframe = genereateOption(this.$awt('aw.common.delivery_method_modal.step3.step1.cheapest'), options.cheapestTimeframe);
          }

          result.defaultOption = defaultOption;

          return result;
        },
      }),
    },
    watch: {
      isCalendarExpanded (newVal) {
        if (newVal && this.selectedTimeframeOption !== 'defaultOption') {
          this.selectedTimeframeOption = 'defaultOption';
        }
      },
      selectedTimeframeOption (newVal) {
        if (newVal) {
          this.$emit('update:isCalendarExpanded', newVal === 'defaultOption');
          if (newVal !== 'defaultOption') {
            const selectedTimeframeId = this.bestTimeFrameOptions[newVal].id;
            const selectedTimeframe = this.timeframes.find(current => current.id === selectedTimeframeId);
            this.selectTimeframe(selectedTimeframe);
          }
        }
      },
    },
    mounted () {
      this.$refs?.firstRadio?.[0]?.$el?.focus();
      if (this.isCalendarExpanded) {
        this.selectedTimeframeOption = 'defaultOption';
      } else {
        this.selectedTimeframeOption = this.bestTimeFrameOptions.earliestTimeframe ? 'earliestTimeframe' : null;
      }
    },
    methods: {
      onMouseUp (newValue) {
        if (newValue === 'defaultOption') {
          this.$emit('update:isBestTimeframesExpanded', false);
          this.$emit('update:isCalendarExpanded', true);
        }
      },
      selectTimeframe (newTimeframe) {
        this.$emit('select-timeframe', newTimeframe);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.radioWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.radioOptionsGroup {
  margin: 0 auto;
}

.timeframeOption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &Description {
    font-weight: $font-weight-normal-v2 !important;
  }
}
</style>
