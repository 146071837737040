import { mapState } from 'pinia';
import { DELIVERY_AREA_MODEL } from '~~/shop/awPlugins/app-config';
import { useDeliveryStore } from '~~/shop/stores/delivery.js';

export default {
  computed: {
    ...mapState(useDeliveryStore, {
      deliverySetup: state => state?.setup || {},
      deliveryMethodText (state) {
        return this.$awt(state?.setup.type === DELIVERY_AREA_MODEL.PUBLIC_AREA ? 'aw.common.delivery_method_modal.store_selector.home_delivery' : 'aw.common.delivery_method_modal.store_selector.pick_up_from_store');
      },
      selectedTimeframe (state) {
        const localTimeframe = state.timeframeListSelectedTimeframe;
        const remoteTimeframe = state.selectedTimeframe;
        return localTimeframe || remoteTimeframe;
      },
    }),
    deliveryDateText () {
      return this.$date(this.selectedTimeframe?.from, {
        month: 'long',
        day: '2-digit',
        weekday: 'long',
      });
    },
    deliveryTimeText () {
      return `${this.selectedTimeframe?.displayFrom} - ${this.selectedTimeframe?.displayTo}`;
    },
    deliveryPriceText () {
      return this.$price({ gross: this.selectedTimeframe?.price?.grossDiscounted }, this.selectedTimeframe?.price?.currency);
    },
    singleLineDeliveryInfo () {
      return [
        `${this.deliveryPriceText},`,
        `${this.deliveryMethodText}:`,
        this.deliverySetup.type === DELIVERY_AREA_MODEL.PUBLIC_AREA ? this.deliverySetup.postCode : '',
        this.deliverySetup.name,
        this.deliveryDateText,
        this.deliveryTimeText,
      ].join(' ').replace(/\s+/g, ' ');
    },
  },
};
