<template>
  <div :class="$style.wrapper">
    <div :class="$style.hours">
      <div v-for="hour in hourRange" :key="hour" :class="[$style.hoursMarker, {[$style.hoursMarkerActive]: isActiveHour(hour)}]">
        {{ hour }}:00
      </div>
    </div>
    <div :class="$style.canvas">
      <div :class="$style.background">
        <div v-for="hour in hourRange" :key="hour" :class="$style.separator" />
      </div>
      <div :class="$style.foreground">
        <div v-if="timeframe" :class="$style.selected" :style="{gridRowStart: calcRowPoint(timeframe.displayFrom), gridRowEnd: calcRowPoint(timeframe.displayTo)}">
          <div :class="$style.selectedFrom">
            {{ timeframe.displayFrom }}
          </div>
          <div :class="$style.selectedUntil">
            {{ timeframe.displayTo }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AwSelectedTimeframeVisualizer',
    props: {
      timeframe: {
        type: Object,
        default: null,
      },
    },
    computed: {
      hourRange () {
        const from = 8;
        const until = 22;
        const result = [];
        for (let i = from; i <= until; i++) {
          result.push(i);
        }
        return result;
      },
    },
    methods: {
      calcRowPoint (payload) {
        const hours = this.getHour(payload);
        return hours - 8 + 1;
      },
      getHour (payload) {
        return parseInt(payload?.split(':')?.[0]);
      },
      isActiveHour (hour) {
        if (!this.timeframe) {
          return false;
        }
        const activeFrom = this.getHour(this.timeframe.displayFrom);
        const activeUntil = this.getHour(this.timeframe.displayTo);
        return hour >= activeFrom && hour <= activeUntil;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$timeframe-visualizer-row-number: 14;
$timeframe-visualizer-row-height: 1fr;

.wrapper {
  font-family: $secondary-font;
  display: grid;
  width: 216px;
  min-height: 412px;
  padding: 38px 20px 40px 36px;
  border-radius: 16px 0 0 16px;
  background: $color-background-4;
  grid-template-columns: min-content auto;
  gap: 16.5px;

  .hours {
    display: grid;
    grid-template-rows: repeat($timeframe-visualizer-row-number, $timeframe-visualizer-row-height);
    margin-top: -10px;
    margin-bottom: -10px;

    &Marker {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: right;
      color: $color-text-tertiary;

      &Active {
        color: $color-text-primary;
      }
    }
  }

  .canvas {
    position: relative;
    height: 100%;

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: grid;
      grid-template-rows: repeat($timeframe-visualizer-row-number, $timeframe-visualizer-row-height);

      .separator {
        width: 100%;
        height: 0;
        border-bottom: 1px dashed $color-dash-separator;
      }
    }

    .foreground {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: grid;
      grid-template-rows: repeat($timeframe-visualizer-row-number, $timeframe-visualizer-row-height);

      .selected {
        font-size: 12px;
        font-weight: 900;
        line-height: 16px;
        position: relative;
        width: 100%;
        border-top: 2px solid $color-sage-450;
        border-bottom: 2px solid $color-sage-450;
        background-color: $color-background-success;

        &From {
          display: flex;
          align-items: center;
          justify-content: center;
          width: min-content;
          height: 16px;
          padding: 2px 8px;
          color: $color-white;
          border-radius: 0 0 6px 6px;
          background-color: $color-sage-450;
        }

        &Until {
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: min-content;
          height: 16px;
          padding: 2px 8px;
          color: $color-white;
          border-radius: 6px 6px 0 0;
          background-color: $color-sage-450;
        }
      }
    }
  }
}
</style>
