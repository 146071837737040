<template>
  <div :class="$style.wrapper">
    <aw-selected-timeframe-visualizer v-if="screenRange['tablet-min']" :timeframe="selectedTimeframe" />
    <div :class="$style.rightSide">
      <lv-heading level="4" tag="h4" version2>
        {{ $awt('aw.common.delivery_method_modal.step3.step2.title') }}
      </lv-heading>
      <div
        v-for="timeframes in timeframesBySelectedDay"
        :key="timeframes.title"
        :class="[$style.timeframes,
                 {
                   [$style.timeframesSingle]: timeframesBySelectedDay.length === 1,
                   [$style.timeframesMultiple]: timeframesBySelectedDay.length > 1,

                 }]"
      >
        <div v-if="timeframesBySelectedDay.length > 1" :class="$style.titleWrapper">
          <h5 :class="$style.title">
            {{ timeframes.title }}
          </h5>
          <div :class="$style.separator" />
        </div>

        <div :class="$style.list">
          <div :class="$style.options">
            <aw-timeframe-day-render
              v-for="timeframe in timeframes.timeframes"
              :key="timeframe.id"
              :timeframe="timeframe"
              tabindex="0"
              :has-autofocus="timeframe.hasAutofocus"
              :aria-selected="isSelected(timeframe)"
              :aria-label="getAriaLabelByTimerFrame(timeframe)"
              :selected="isSelected(selectedTimeframe, timeframe)"
              @select-timeframe="event => $emit('select-timeframe', event)"
              @enter="$emit('submit-form')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import AwTimeframeDayRender from './AwTimeframeDayRender';
  import AwSelectedTimeframeVisualizer from './AwSelectedTimeframeVisualizer.vue';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useDeliveryStore } from '~~/shop/stores/delivery';

  export default {
    name: 'AwTimeframeList',
    components: {
      LvHeading,
      AwSelectedTimeframeVisualizer,
      AwTimeframeDayRender,
    },
    props: {
      timeframesBySelectedDay: {
        type: Array,
        required: true,
      },
    },
    emits: ['select-timeframe', 'submit-form'],
    computed: {
      ...mapState(useDeliveryStore, {
        selectedTimeframe: state => state.timeframeListSelectedTimeframe,
      }),
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
    },
    methods: {
      getAriaLabelByTimerFrame (timeframe) {
        const time = `${timeframe.displayFrom} - ${timeframe.displayTo}`;
        const label = this.$awt('aw.common.delivery_method_modal.step3.step2.timeframe_render.aria_label');
        const selectedPart = this.isSelected(this.selectedTimeframe, timeframe) ? `(${this.$awt('aw.global.selected')})` : '';
        return `${label} ${time} ${selectedPart}`;
      },
      isSelected (selected, current) {
        return selected?.id === current?.id || false;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
}

.rightSide {
  flex-grow: 1;
  padding: 20px 16px;
  border-radius: 16px;
  background: $color-background-4;

  @include tablet(min) {
    padding: 40px;
    border-radius: 0 16px 16px 0;
  }

}

.timeframes {
  width: 100%;

  &Single {
    margin-top: 20px;
    @include tablet(min) {
      margin-top: 40px;
    }
  }

  &Multiple {
    margin-top: 32px;
    @include tablet(min) {
      margin-top: 20px;
    }
  }

  .title {
    font-size: 14px;
    font-weight: $font-weight-bold-v2;
    text-align: left;
    color: $color-text-primary;

    &Wrapper {
      display: grid;
      align-items: center;
      margin-bottom: 16px;
      gap: 16px;
      grid-template-columns: max-content auto;
    }
  }

  .separator {
    width: 100%;
    border-top: 1px dashed $color-dash-separator;
  }

  .list {
    position: relative;

    .options {
      position: relative;
      z-index: 1;
      display: grid;
      grid-template-columns: repeat(auto-fit, 142px);
      gap: 16px 12px;

      @include tablet(min) {
        grid-template-columns: repeat(auto-fit, 168px);
      }
    }
  }
}
</style>
