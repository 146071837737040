<template>
  <div>
    <aw-notification-item
      v-if="errorNotification"
      :key="`error-notification-${Object.keys(errorNotification || {}).length}`"
      :notification="errorNotification"
      index="1"
      @close-notification-item="errorNotification = null;"
      @dispatch-callback-success="dispatchCallbackSuccess"
    />
    <transition v-bind="transitionOpacity">
      <aw-spinner v-if="isLoading" />
      <aw-form v-else-if="selectedDeliveryMethod === deliveryMethodsEnum.HOME_DELIVERY" v-model="v$" :class="$style.postcodeForm">
        <p :class="$style.description">
          {{ $awt('aw.common.delivery_method_modal.step2.home_description') }}
        </p>
        <aw-form-row-version2
          ref="deliverySelector"
          v-model="postCode"
          tabindex="0"
          show-form-row-label
          :dropdown-icon-attrs="{ size: 14 }"
          @typeahead-enter.prevent="onEnter"
          @on-submit.stop="onEnter"
        />
      </aw-form>
      <div v-else-if="selectedDeliveryMethod === deliveryMethodsEnum.PICK_UP_FROM_STORE" :class="$style.storeForm">
        <template v-if="selectedStore">
          <p :class="$style.description">
            {{ $awt('aw.common.delivery_method_modal.step2.store_description') }}
          </p>
          <aw-select-version2
            ref="deliverySelector"
            v-model:selected-options="selectedStore"
            tabindex="0"
            :class="$style.storeFormSelector"
            :inline-label-text="$awt('aw.timeframe.modal.dm.select_store.default_value')"
            :multiple="false"
            dynamic-width
            :options="storeList"
            :dropdown-icon-attrs="{
              name: 'arrow-down-16',
              size: 14
            }"
            @select-enter="$emit('submit-form')"
          />
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
  import { defineAsyncComponent, nextTick } from 'vue';
  import { useVuelidate } from '@vuelidate/core';
  import { mapState } from 'pinia';
  import { useRuntimeConfig } from 'nuxt/app';
  import deliveryMethodsEnum from './enum/deliveryMethodsEnum.js';
  import modalSteps from './enum/modalStepsEnum';
  import { DELIVERY_AREA_MODEL } from '~~/shop/awPlugins/app-config';
  import { transitionOpacity } from '~~/common/utils/transition.js';
  import AwFormRowVersion2 from '~~/common/components/Form/AwFormRowVersion2';
  import AwForm from '~~/common/components/Form/AwForm';
  import addressApi from '~~/common/mixins/addressApi';
  import { deliveryMethodsEnumToDeliveryMethod, PUSH_CHANGE_DELIVERY_METHOD, CDM_POSTCODE } from '~~/common/plugins/aw-analytics.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useDeliveryStore } from '~~/shop/stores/delivery';

  export default {
    name: 'AwDeliveryPostcodeForm',
    components: {
      AwFormRowVersion2,
      AwForm,
      AwSpinner: defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner')),
      AwSelectVersion2: defineAsyncComponent(() => import('~~/common/components/Common/AwSelectVersion2')),
      AwNotificationItem: defineAsyncComponent(() => import('~~/common/components/Common/AwNotificationItem')),
    },
    mixins: [addressApi],
    emits: [
      'submit-form',
      'validation-status-changed',
      'go-to-step',
    ],
    setup: () => ({ v$: useVuelidate() }),
    data () {
      const config = useRuntimeConfig();
      return {
        transitionOpacity,
        errorNotification: null,
        deliveryMethodsEnum,
        DELIVERY_AREA_MODEL,
        postCodeIsLoading: true,
        postCode: {
          model: '',
          version2: true,
          type: 'typeahead',
          name: 'postCode',
          label: this.$awt('aw.common.delivery_method_modal.your_zip_input'),
          options: [],
          openFromOutside: false,
          widgetAttrs: {
            id: 'post_code',
            autocomplete: 'off',
            maxLength: config.public.postcodeMaxLength,
          },
          widgetListeners: {
            select: this.setCity,
            filter: this.filterByZipCode,
          },
          widgetProps: {
            hasChevron: true,
            optionTemplate: '{{label}} {{settlementName}}',
          },
          labelAttrs: {
            showAsterisk: false,
            for: 'post_code',
            version2: true,
          },
          tokens: {
            postCodeValidator: 'aw.bandd.post_code_format',
          },
        },
        city: {
          model: '',
          type: 'text',
          name: 'city',
          labelAttrs: {
            for: 'city',
          },
          widgetAttrs: {
            id: 'city',
          },
        },
        selectedStore: null,
      };
    },
    computed: {
      isLoading () {
        return this.apiIsLoading || (this.selectedDeliveryMethod === deliveryMethodsEnum.PICK_UP_FROM_STORE && !this.selectedStore) || this.postCodeIsLoading;
      },
      ...mapState(useDeliveryStore, {
        selectedDeliveryMethod (state) {
          return state.selectedDeliveryMethod || (this.isShippingSelected ? deliveryMethodsEnum.HOME_DELIVERY : deliveryMethodsEnum.PICK_UP_FROM_STORE);
        },
        deliveryMethod: state => state?.setup,
        isShippingSelected () {
          return this.deliveryMethod?.type === this.DELIVERY_AREA_MODEL.PUBLIC_AREA;
        },
        storeList (state) {
          return [{
            id: 0,
            name: this.$awt('aw.timeframe.modal.dm.select_store.default_value'),
          }].concat(state.departmentStores);
        },
      }),
      ...mapState(useUserInterfaceStore, {
        apiIsLoading (state) {
          return state.loading.some(element => element.match(/push-method-[0-9]+-(public_area|department_store)/gi));
        },
      }),
    },
    watch: {
      v$: {
        deep: true,
        handler () {
          if (this.selectedDeliveryMethod === deliveryMethodsEnum.HOME_DELIVERY) {
            this.$emit('validation-status-changed', !this.v$.$invalid);
          }
        },
      },
      selectedStore: {
        deep: true,
        handler (newVal) {
          if (this.selectedDeliveryMethod === deliveryMethodsEnum.PICK_UP_FROM_STORE) {
            const isValid = newVal.name !== this.storeList[0].name;
            this.$emit('validation-status-changed', isValid);
          }
        },
      },
    },
    async mounted () {
      const deliveryStore = useDeliveryStore();
      await deliveryStore.fetchDepartmentStores();
      this.initStoreOptionList();
      await this.loadCurrentState();
      setTimeout(() => {
        this.$refs?.deliverySelector?.$el?.focus();
      }, 500);
    },
    validations () {
      const config = useRuntimeConfig();
      return {
        postCode: {
          model: {
            postCodeValidator (value) {
              return useUserInterfaceStore().postCodeValidator(value, config.public);
            },
          },
        },
      };
    },
    methods: {
      dispatchCallbackSuccess () {
        this.$emit('go-to-step', modalSteps.DELIVERY_TIMEFRAME);
      },
      async loadCurrentState () {
        if (!this.deliveryMethod) {
          this.postCodeIsLoading = false;
          return;
        }
        if (this.isShippingSelected) {
          const selectedPostCode = this.deliveryMethod.postCode;
          await this.filterByZipCode(selectedPostCode);
          nextTick(() => {
            this.postCode.model = selectedPostCode;
            this.postCodeIsLoading = false;
            const postCodeOptions = this.postCode.options;
            if (postCodeOptions.length > 0) {
              const currentOption = postCodeOptions[0];
              this.setCity(currentOption);
            }
          });
        } else {
          this.postCodeIsLoading = false;
        }
      },
      initStoreOptionList () {
        if (!this.isShippingSelected && this.deliveryMethod?.id) {
          this.selectedStore = this.storeList.find(({ id }) => id === this.deliveryMethod.id);
        } else {
          this.selectedStore = this.storeList[0];
        }
      },
      onEnter () {
        this.$emit('submit-form');
      },
      async onSubmit () {
        if (!this.apiIsLoading) {
          const isSuccess = await (this.selectedDeliveryMethod === deliveryMethodsEnum.HOME_DELIVERY ? this.submitHomeDelivery : this.submitPickupInStore)();
          try {
            if (isSuccess) {
              this.$awAnalytics[PUSH_CHANGE_DELIVERY_METHOD]({
                deliveryMethodId: deliveryMethodsEnumToDeliveryMethod(this.selectedDeliveryMethod),
                checkoutType: 'FOOD',
                changeTypes: [CDM_POSTCODE],
              });
            }
          } catch (error) {
            this.$logger.error(error);
          }
          return isSuccess;
        }
      },
      async submitPickupInStore () {
        const deliveryStore = useDeliveryStore();
        const result = await deliveryStore.pushMethod({
          type: DELIVERY_AREA_MODEL.PICK_UP_FROM_STORE,
          areaId: parseInt(this.selectedStore.id),
        });

        if (result?.errorData) {
          this.errorNotification = result;
          return false;
        } else if (!result?.fetchOrderErrorStatus || result?.fetchOrderErrorResult?.name === 'accept') {
          return true;
        }
      },
      async submitHomeDelivery () {
        this.v$.$touch();
        if (!this.v$.$error) {
          return await this.setShippingData();
        } else {
          return false;
        }
      },
      async setShippingData () {
        const deliveryStore = useDeliveryStore();
        this.postCodeIsLoading = true;
        const result = await deliveryStore.pushMethod({
          type: DELIVERY_AREA_MODEL.PUBLIC_AREA,
          areaId: parseInt(this.selectedPublicAreaId),
        });
        if (result?.errorData || result?.type === 'error') {
          this.errorNotification = result;
          this.postCodeIsLoading = false;
          return false;
        }
        this.postCodeIsLoading = false;
        return true;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.description {
  margin-bottom: 20px;

  @include tablet(min) {
    margin-bottom: 40px;
  }
}

.postcodeForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  svg {
    font-size: 14px;
  }
}

.storeForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &Selector {
    width: max-content;
  }
}
</style>
