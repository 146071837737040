<template>
  <aw-modal
    :opened="opened"
    :closable="closable"
    size="lg"
    :id-label="idAriaLabelledBy"
    :modal-title="modalTitle"
    :modal-title-attrs="{
      id: componentTopHeadingId,
      tag: 'h2',
      class: [
        $style.heading,
        {
          [$style.headingMarginless]: isSuccessState || onlyTimeframes,
        },
      ]
    }"
    @close-modal="closeModal"
  >
    <template #content>
      <div :class="[$style.wrapper,AnaliticsClasses.storeSelectorStep[activeStep]]">
        <div v-if="!onlyTimeframes" :class="$style.deliverySteps">
          <aw-delivery-method-steps
            v-if="!isSuccessState"
            :active-step="activeStep"
            :steps="modalSteps"
            :class="$style.deliveryStepsComponent"
            @edit="goToStep"
          />
        </div>
        <aw-separator :margin-top="onlyTimeframes ? 'none' : 'md' " />
        <transition v-bind="transitionOpacity">
          <aw-delivery-method-form
            v-if="activeStep === steps.DELIVERY_METHOD"
            ref="deliveryMethodForm"
            @validation-status-changed="setNextButtonDisabled"
            @submit-form="goToNextStep"
          />
          <aw-delivery-postcode-form
            v-else-if="activeStep === steps.DELIVERY_POSTCODE"
            ref="deliveryPostcodeForm"
            @go-to-step="goToStep"
            @validation-status-changed="setNextButtonDisabled"
            @submit-form="goToNextStep"
          />
          <aw-delivery-timeframe-form
            v-else-if="activeStep === steps.DELIVERY_TIMEFRAME"
            ref="deliveryTimeFrameForm"
            :only-timeframes="onlyTimeframes"
            @validation-status-changed="setNextButtonDisabled"
            @go-to-step="goToStep"
            @change-button-layout="changeButtonLayout"
            @close-modal="closeModal"
            @is-success-state="setSuccessState"
            @submit-form="goToNextStep"
          />
        </transition>
        <aw-separator :margin-top="secondSeparatorWithoutMarginTop ? 'none' : 'md'" />
        <h3 class="awSrOnlyAbs" v-text="$awt('aw.common.delivery_method_modal.buttons_title')" />
        <div v-if="buttonLayout === buttonLayouts.NEXT_AND_CANCEL" :class="$style.bottomWrapper">
          <div class="awSrOnlyAbs" v-text="singleLineDeliveryInfo" />
          <lv-button
            styling="primary-dark"
            :class="$style.nextStepButton"
            :disabled="nextButtonDisabled"
            :aria-label="layoutBtnText"
            @click="goToNextStep"
          >
            {{ $awt('aw.common.delivery_method_modal.buttons.ok') }}
          </lv-button>
          <lv-button
            styling="secondary-dark"
            :class="$style.nextStepButton"
            @click="closeModal"
          >
            {{ $awt('aw.common.delivery_method_modal.buttons.cancel') }}
          </lv-button>
        </div>
        <div v-else-if="buttonLayout === buttonLayouts.LOGIN_REQUIRED" :class="$style.bottomWrapper">
          <lv-button
            styling="primary-dark"
            :class="$style.loginButton"
            :loading-id="LOGGED_OUT_TOASTER_TIMEFRAME_MODAL"
            @click="doLogin"
          >
            {{ $awt('aw.common.delivery_method_modal.buttons.login') }}
          </lv-button>
          <lv-button
            styling="secondary-dark"
            :class="$style.backToBrowsingButton"
            @click="closeModal"
          >
            {{ $awt('aw.common.delivery_method_modal.buttons.back_to_browsing') }}
          </lv-button>
        </div>
        <div v-else-if="buttonLayout === buttonLayouts.NEXT" :class="$style.bottomWrapper">
          <lv-button
            styling="primary-dark"
            :class="{[$style.nextStepButton]: !onlyTimeframes}"
            :disabled="nextButtonDisabled"
            :aria-label="`${$awt('aw.common.delivery_method_modal.buttons.next')} ${nextModalStep ? nextModalStep.name : $awt('aw.common.delivery_method_modal.buttons.overview')}`"
            @click="goToNextStep"
          >
            {{
              $awt(onlyTimeframes ? 'aw.common.delivery_method_modal.buttons.save_timeframe' : 'aw.common.delivery_method_modal.buttons.next')
            }}
          </lv-button>
        </div>
        <div v-else-if="buttonLayout === buttonLayouts.OK" :class="$style.bottomWrapper">
          <lv-button
            styling="primary-dark"
            :class="$style.nextStepButton"
            :disabled="nextButtonDisabled"
            :aria-label="layoutBtnText"
            @click="goToNextStep"
          >
            {{ $awt('aw.common.delivery_method_modal.buttons.ok') }}
          </lv-button>
        </div>
        <div v-else-if="buttonLayout === buttonLayouts.OK_AND_CLOSE" :class="$style.bottomWrapper">
          <lv-button
            styling="primary-dark"
            :class="$style.nextStepButton"
            :disabled="nextButtonDisabled"
            @click="closeModal"
          >
            {{ $awt('aw.common.delivery_method_modal.buttons.ok') }}
          </lv-button>
        </div>

        <div
          v-if="enableServiceAgreements && buttonLayout !== buttonLayouts.LOGIN_REQUIRED"
          :class="$style.termsAndConditions"
        >
          <lv-button styling="link-primary-version2" shrink @click="termsAndConditions.click">
            {{ $awt(termsAndConditions.token) }}
          </lv-button>
        </div>
      </div>
    </template>
  </aw-modal>
</template>

<script>
  import { mapState } from 'pinia';
  import { useRuntimeConfig } from 'nuxt/app';
  import AwDeliveryMethodSteps from './AwDeliveryMethodSteps';
  import AwDeliveryMethodForm from './AwDeliveryMethodForm';
  import AwDeliveryPostcodeForm from './AwDeliveryPostcodeForm';
  import AwDeliveryTimeframeForm from './AwDeliveryTimeframeForm';
  import steps from './enum/modalStepsEnum';
  import buttonLayouts from './enum/buttonLayoutsEnum';
  import { transitionOpacity } from '~~/common/utils/transition.js';
  import AwSeparator from '~~/common/components/Common/AwSeparator';
  import { /* LvHeading, */ LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { M_DELIVERY_METHOD } from '~~/common/config/Modal.js';
  import { focusElement } from '~~/common/config/Navigation.js';
  import { uuid4 } from '~~/common/utils';
  import AwModal from '~~/common/components/Common/AwModal';
  import AnaliticsClasses from '~~/common/config/AnaliticsClasses';
  import userLoginAndRegistrationMixin from '~~/common/mixins/userLoginAndRegistrationMixin';
  import { useModalStore } from '~~/common/stores/modal';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import deliveryDataMixin from '~~/shop/mixins/deliveryDataMixin.js';
  import { useUserStore } from '~~/common/stores/user.js';
  import { LOGGED_OUT_TOASTER_TIMEFRAME_MODAL } from '~~/common/config/LoadingIdConfig.js';

  export default {
    name: 'AwDeliveryMethodAndTimeframeModal',
    components: {
      AwModal,
      // LvHeading,
      LvButton,
      AwSeparator,
      AwDeliveryMethodSteps,
      AwDeliveryMethodForm,
      AwDeliveryPostcodeForm,
      AwDeliveryTimeframeForm,
    },
    mixins: [
      userLoginAndRegistrationMixin,
      deliveryDataMixin,
    ],
    data () {
      const modalSteps = [
        {
          index: 1,
          step: steps.DELIVERY_METHOD,
          name: this.$awt('aw.common.delivery_method_modal.steps.1.name'),
          title: this.$awt('aw.common.delivery_method_modal.steps.1.title'),
        },
        {
          index: 2,
          step: steps.DELIVERY_POSTCODE,
          name: this.$awt('aw.common.delivery_method_modal.steps.2.name'),
          title: this.$awt('aw.common.delivery_method_modal.steps.2.title'),
        },
        {
          index: 3,
          step: steps.DELIVERY_TIMEFRAME,
          name: this.$awt('aw.common.delivery_method_modal.steps.3.name'),
          title: this.$awt('aw.common.delivery_method_modal.steps.3.title'),
        },
      ];
      return {
        transitionOpacity,
        componentTopHeadingId: 'aw_delivery_method_and_timeframe_modal',
        layoutBtnText: null,
        buttonLayout: buttonLayouts.NEXT,
        activeStep: modalSteps[0].step,
        buttonLayouts,
        steps,
        modalSteps,
        termsAndConditions:
          {
            token: 'aw.common.delivery_method_modal.privacy_policy',
            click: () => {
              const modalStore = useModalStore();
              modalStore.openStaticContentModal({
                content: 'privacy_policy',
                title: this.$awt('gdpr_modal_text_content_title'),
              });
            },
          },
        idAriaLabelledBy: `aria_labelledby_${uuid4()}`,
        nextButtonDisabled: true,
        isSuccessState: false,
        AnaliticsClasses,
        LOGGED_OUT_TOASTER_TIMEFRAME_MODAL,
      };
    },
    computed: {
      ...mapState(useUserStore, {
        isCareerUser: state => state.isCareerUser,
      }),
      ...mapState(useDeliveryStore, {
        departmentStores: state => state.departmentStores,
        deliveryMethod: state => state.setup,
        secondSeparatorWithoutMarginTop: state => state.secondSeparatorWithoutMarginTop,
      }),
      ...mapState(useUserInterfaceStore, {
        apiIsLoading (state) {
          return state.loading.some(element => element.match(/push-method-[0-9]+-(public_area|department_store)/gi));
        },
      }),
      ...mapState(useModalStore, {
        modal: state => state.activeGenericModal,
      }),
      opened () {
        return this.modal.type === M_DELIVERY_METHOD;
      },
      closable () {
        return this.modal.item.closable;
      },
      step () {
        return this.modal.item.data.step;
      },
      onlyTimeframes () {
        return !!this.modal.item.data.onlyTimeframes;
      },
      currentModalStepIdx () {
        return this.modalSteps.findIndex(step => step.step === this.activeStep);
      },
      nextModalStep () {
        return this.modalSteps[this.currentModalStepIdx + 1];
      },
      modalTitle () {
        if (this.onlyTimeframes) {
          return this.$awt('aw.common.checkout_delivery_timeframe.title');
        }
        if (this.isSuccessState && this.activeStep === steps.DELIVERY_TIMEFRAME) {
          return this.$awt('aw.common.delivery_method_modal.success_title');
        }
        return this.modalSteps[this.currentModalStepIdx]?.title || null;
      },
      enableServiceAgreements () {
        const config = useRuntimeConfig();
        return config.public.enableServiceAgreements;
      },
    },
    watch: {
      activeStep (newVal) {
        if (newVal !== this.steps.DELIVERY_TIMEFRAME) {
          this.buttonLayout = this.buttonLayouts.NEXT;
        }
      },
    },
    mounted () {
      const deliveryStore = useDeliveryStore();
      deliveryStore.setSecondSeparatorWithoutMarginTop(false);
      if (this.step) {
        this.activeStep = this.step;
        return;
      }
      if (this.deliveryMethod) {
        this.activeStep = this.steps.DELIVERY_TIMEFRAME;
      } else {
        this.activeStep = this.steps.DELIVERY_METHOD;
      }
    },
    methods: {
      doLogin () {
        this.closeModal();
        this.openLoginModal(this.isCareerUser ? {
          loadingId: LOGGED_OUT_TOASTER_TIMEFRAME_MODAL,
          showToaster: true,
          toasterAttributes: {
            title: this.$awt('aw.career.toast.logged_out_from_career_profile'),
          },
        } : {});
      },
      closeModal () {
        const modalStore = useModalStore();
        if (!this.deliveryMethod) {
          this.$cookies.set('selected_postcode', JSON.stringify({ closed: true }), {
            path: '/',
            maxAge: 7 * 24 * 60 * 60,
          });
        }

        modalStore.closeDeliveryMethodModal();
      },
      changeButtonLayout ({
        newLayout,
        layoutBtnText = null,
      }) {
        this.buttonLayout = this.buttonLayouts[newLayout] || this.buttonLayouts.NEXT;
        this.layoutBtnText = layoutBtnText;
      },
      setSuccessState (payload) {
        this.isSuccessState = payload;
        if (payload) {
          focusElement(document.getElementById(this.componentTopHeadingId));
        }
      },
      goToStep (step) {
        this.activeStep = step;
      },
      async goToNextStep () {
        let success = true;
        const nextStepToSwitch = this.nextModalStep?.step;
        switch (this.activeStep) {
        case this.steps.DELIVERY_METHOD:
          success = this.$refs.deliveryMethodForm.onSubmit();
          break;
        case this.steps.DELIVERY_POSTCODE:
          success = await this.$refs.deliveryPostcodeForm.onSubmit();
          break;
        case this.steps.DELIVERY_TIMEFRAME:
          success = await this.$refs.deliveryTimeFrameForm.onSubmit();
          break;
        }
        if (success && nextStepToSwitch) {
          this.activeStep = nextStepToSwitch;
        }
      },
      setNextButtonDisabled (payload) {
        this.nextButtonDisabled = !payload;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  font-family: $secondary-font;
  height: 100%;
  //@include mobile(max) {
  //  overflow-x: hidden;
  //  overflow-y: auto;
  //}
}

.heading {
  margin-bottom: 28px !important;

  @include tablet(min) {
    margin-bottom: 48px !important;
  }

  &Marginless {
    margin-bottom: 0 !important;
  }
}

.deliverySteps {
  display: flex;
  align-items: center;
  justify-content: center;

  &Component {
    width: 75%;
  }
}

.bottomWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  @include tablet(min) {
    flex-direction: row;
  }

  .nextStepButton {
    width: 132px;
  }

  .loginButton {
    width: 150px;
  }

  .backToBrowsingButton {
    width: 209px;
  }
}

.termsAndConditions {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  text-decoration: underline;

  button {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
