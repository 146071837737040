<template>
  <div :class="$style.wrapper">
    <h3
      ref="componentTopHeading"
      tabindex="-1"
      class="awSrOnlyAbs awSrOnlyInitialW"
      v-text="$awt('aw.common.delivery_method_modal.step3.step4.title')"
    />
    <div :class="$style.tile">
      <div>
        <h4 :class="$style.title" v-text="dataTableTitles.deliveryMethod" />
        <div :class="$style.data">
          {{ deliveryMethodText }}
        </div>
      </div>
      <lv-button styling="link-primary" :class="$style.modifyButton" @click="goToStep(modalSteps.DELIVERY_METHOD)">
        {{ $awt('aw.common.delivery_method_modal.step3.step4.modify') }}
        <span class="awSrOnly" v-text="dataTableTitles.deliveryMethod" />
      </lv-button>
    </div>
    <div :class="$style.separatorLine" />
    <div :class="$style.tile">
      <div>
        <h4 :class="$style.title" v-text="dataTableTitles.postCode" />
        <div :class="$style.data">
          {{ deliverySetup.type === DELIVERY_AREA_MODEL.PUBLIC_AREA ? deliverySetup.postCode : deliverySetup.name }}
        </div>
      </div>
      <lv-button styling="link-primary" :class="$style.modifyButton" @click="goToStep(modalSteps.DELIVERY_POSTCODE)">
        {{ $awt('aw.common.delivery_method_modal.step3.step4.modify') }}
        <span class="awSrOnly" v-text="dataTableTitles.postCode" />
      </lv-button>
    </div>
    <div :class="$style.separatorLine" />
    <div :class="$style.tile">
      <div>
        <h4 :class="$style.title" v-text="dataTableTitles.date" />
        <div :class="$style.data">
          {{ deliveryDateText }}
        </div>
      </div>
      <lv-button styling="link-primary" :class="$style.modifyButton" @click="goToStep(modalSteps.DELIVERY_TIMEFRAME)">
        {{ $awt('aw.common.delivery_method_modal.step3.step4.modify') }}
        <span class="awSrOnly" v-text="dataTableTitles.date" />
      </lv-button>
    </div>
    <div :class="$style.separatorLine" />
    <div :class="$style.tile">
      <div>
        <h4 :class="$style.title" v-text="dataTableTitles.hour" />
        <div :class="$style.data">
          {{ deliveryTimeText }}
        </div>
      </div>
      <lv-button styling="link-primary" :class="$style.modifyButton" @click="goToStep(modalSteps.DELIVERY_TIMEFRAME)">
        {{ $awt('aw.common.delivery_method_modal.step3.step4.modify') }}
        <span class="awSrOnly" v-text="dataTableTitles.hour" />
      </lv-button>
    </div>
    <div :class="$style.separatorLine" />
    <div :class="$style.tile">
      <div>
        <h4 :class="$style.title">
          {{ $awt('aw.common.delivery_method_modal.step3.step4.delivery_price') }}
        </h4>
        <div :class="$style.data">
          {{ deliveryPriceText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import modalSteps from '../enum/modalStepsEnum';
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { DELIVERY_AREA_MODEL } from '~~/shop/awPlugins/app-config';
  import deliveryDataMixin from '~~/shop/mixins/deliveryDataMixin.js';

  export default {
    name: 'AwSelectedDeliveryDataTable',
    components: {
      LvButton,
    },
    mixins: [
      deliveryDataMixin,
    ],
    emits: ['go-to-step'],
    data () {
      return {
        DELIVERY_AREA_MODEL,
        modalSteps,
      };
    },
    computed: {
      dataTableTitles () {
        return {
          deliveryMethod: this.$awt('aw.common.delivery_method_modal.step3.step4.delivery_method').replace(/:$/, ''),
          postCode: (this.deliverySetup.type === DELIVERY_AREA_MODEL.PUBLIC_AREA
            ? this.$awt('aw.common.delivery_method_modal.step3.step4.postcode')
            : this.$awt('aw.common.delivery_method_modal.step3.step4.store_name')
          ).replace(/:$/, ''),
          date: this.$awt('aw.common.delivery_method_modal.step3.step4.date').replace(/:$/, ''),
          hour: this.$awt('aw.common.delivery_method_modal.step3.step4.timeframe').replace(/:$/, ''),
        };
      },
    },
    mounted () {
      this.$refs?.componentTopHeading?.focus();
    },
    methods: {
      goToStep (step) {
        this.$emit('go-to-step', step);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include tablet(min) {
    flex-direction: row;
  }

  .tile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    @include tablet(min) {
      flex-direction: column;
      justify-content: space-between;
      width: 20%;
      padding: 24px;
    }

    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 4px;
      color: $color-text-secondary;

      @include tablet(min) {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }

    .data {
      font-size: 14px;
      font-weight: $font-weight-bold-v2;
      line-height: 20px;
      color: $color-text-primary;
      @include tablet(min) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .modifyButton {
      font-weight: $font-weight-medium-v2;
      width: 88px;
      padding: 0;
      text-align: left;
      @include tablet(min) {
        margin-top: 24px;
      }
    }

  }
}

.separatorLine {
  width: 100%;
  border-right: none;
  border-bottom: 1px solid $color-border;

  @include tablet(min) {
    width: unset;
    border-right: 1px solid $color-border;
    border-bottom: none;
  }
}
</style>
