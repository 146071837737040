<template>
  <div :class="$style.wrapper">
    <aw-radio-group-version2
      v-model="selectedDeliveryMethod"
      :row="screenRange['tablet-min']"
      row-space="md"
      :legend-text="$awt('aw.common.delivery_method_modal.store_selector.legend')"
    >
      <aw-radio-chooser-version2
        v-for="(dm, i) in deliveryMethods"
        :key="i"
        ref="firstRadio"
        label-size="md"
        input-width="lg"
        :value="i"
        :disabled="!dm.enabled"
        @enter="$emit('submit-form')"
      >
        <template #label>
          {{ dm.token }}
        </template>
      </aw-radio-chooser-version2>
    </aw-radio-group-version2>
  </div>
</template>

<script>
  import { required } from '@vuelidate/validators';
  import { mapState } from 'pinia';
  import deliveryMethodsEnum from './enum/deliveryMethodsEnum.js';
  import AwRadioGroupVersion2 from '~~/common/components/Common/Widgets/AwRadioGroupVersion2.vue';
  import AwRadioChooserVersion2 from '~~/common/components/Common/Widgets/AwRadioChooserVersion2.vue';
  import { DELIVERY_AREA_MODEL } from '~~/shop/awPlugins/app-config';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import { useVuelidate } from '@vuelidate/core';

  export default {
    name: 'AwDeliveryMethodForm',
    components: {
      AwRadioGroupVersion2,
      AwRadioChooserVersion2,
    },
    emits: [
      'submit-form',
      'validation-status-changed',
    ],
    setup: () => ({ v$: useVuelidate() }),
    data () {
      return {
        deliveryMethodsEnum,
        DELIVERY_AREA_MODEL,
        deliveryMethods: {
          [deliveryMethodsEnum.HOME_DELIVERY]: {
            token: this.$awt('aw.common.delivery_method_modal.store_selector.home_delivery'),
            enabled: true,
          },
          [deliveryMethodsEnum.PICK_UP_FROM_STORE]: {
            token: this.$awt('aw.common.delivery_method_modal.store_selector.pick_up_from_store'),
            enabled: true,
          },
        },
        selectedDeliveryMethod: '',
      };
    },
    validations: {
      selectedDeliveryMethod: {
        required,
      },
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useDeliveryStore, {
        deliveryMethod: state => state.setup,
      }),
      isShippingSelected () {
        return this.deliveryMethod?.type === this.DELIVERY_AREA_MODEL.PUBLIC_AREA && !this.reset;
      },
    },
    watch: {
      v$: {
        deep: true,
        handler () {
          this.$emit('validation-status-changed', !this.v$.$invalid);
        },
      },
    },
    mounted () {
      if (this.deliveryMethod) {
        this.selectedDeliveryMethod = this.isShippingSelected ? this.deliveryMethodsEnum.HOME_DELIVERY : this.deliveryMethodsEnum.PICK_UP_FROM_STORE;
      }
      this.$refs?.firstRadio?.[0]?.$el?.focus();
    },
    methods: {
      onSubmit () {
        const deliveryStore = useDeliveryStore();
        if (this.v$.$invalid) {
          return false;
        }
        deliveryStore.setTimeframeListSelectedTimeframe(null);
        deliveryStore.setSelectedDeliveryMethod(this.selectedDeliveryMethod);
        return true;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
